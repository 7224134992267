import "./index.css";
import adminIcon from "../../../assests/images/newimages/admin.svg";
import managerIcon from "../../../assests/images/newimages/manager.svg";
import supervisor from "../../../assests/images/newimages/supervisor.svg";
import workers from "../../../assests/images/newimages/worker.svg";
import driver from "../../../assests/images/newimages/driver.svg";
import cmslogo from "../../../assests/images/webp/ccms-logo_11zon.webp";
import handmokup from "../../../assests/images/webp/hand mockup_resize.webp";
import appstore from "../../../assests/images/newimages/app-store.svg";
import googleplay from "../../../assests/images/newimages/google-play.svg";
import managerimage from "../../../assests/images/webp/manager-pic_resized.webp";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation} from "react-i18next";

const HeaderSectionPage = () => {
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState("");
  const { control, register, handleSubmit } = useForm();
  const handleOnSubmit = (data) => {
    const postData = {
      fullName: data.name,
      email: data.email,
      phone: data.phnnumber,
      description: "",
      location: data.city,
    };
    setSuccessMessage("Thank you for choosing us");
    // axios
    // .post("https://api.accruonconsultants.com/accruonConsultantApi/cleanWorldContactMail", postData)
    // .then((response) => {
    //   if (response.data.message == "Success") {
    //     // navigate("thank-you")
    //     setSuccessMessage("Thank you for choosing us")
    //     console.log(response)

    //   }
    // })
  };
  return (
    <>
      <div class="sec-bg">
        <section class="wrapper bg-wrap">
          {/*   <div class="container">
            <div class="grid-cols-2">
              <div class="grid-item-2">
                <div class="team_img_wrapper">
                  <img src={laptop} alt="team-img" />
                </div>
              </div>
              <div class="grid-item-1">
                <h1 class="main-heading">{t("banner_content")}</h1>
                <p class="info-text-m"></p>
              </div>
            </div>
  </div>*/}

          <div class="container">
            <div class="grid-cols-2">
              <div class="grid-item-2">
                <div class="team_img_wrapper">
                  <img src={handmokup} alt="team-img" />
                </div>
              </div>
              <div class="grid-item-1">
                <p>{t("banner_content")}</p>
                <h1 class="main-heading">{t("banner_content1")}</h1>
                <NavLink to="/contact-us" className="get-btn "> Get your free trial</NavLink>
                {/* <a href="" class="get-btn">
                 
                </a> */}
              </div>
            </div>
          </div>
          <div class="banner-strip">
            <div class="container">
              <blockquote>
                {" "}
                <p class="banner-p">
                 "{t("banner_quote")}"
                  <br /> <span class="quote-n"></span>
                </p>
              </blockquote>
              <img src={managerimage} class="photo1" />
            </div>
          </div>
        </section>
      </div>
      <section class="main-section" id="box-four">
        <div class="container">
          <div class="boxes">
            <div class="row" style={{alignItems: "center"}}>
              <div class="col-md-3">
                <div class="f-head">
                  <img src={cmslogo} />
                  {/* <p>
                    <span>C</span>leaning Companies Management System
                  </p> */}
                </div>
              </div>
              <div class="col-md-6 nxt-one">
                <p>
                  One App for all
                </p>
                <div class="img-row">
                  <img src={adminIcon} />
                  <img src={managerIcon} />
                  <img src={supervisor} />
                  <img src={workers} />
                  <img src={driver} />
                </div>
              </div>
              <div class="col-md-3 nxt-two">
                <div class="nxt-two">
                  <NavLink
                    to="https://play.google.com/store/apps/details?id=com.accruon.ccms"
                    target="_blank"
                  >
                    <img src={googleplay} />
                  </NavLink>
                  <NavLink
                    to="https://apps.apple.com/us/app/ccms-accruon/id6477782009"
                    target="_blank"
                  >
                    {" "}
                    <img src={appstore} />
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="main-section" id="box-second">
        <div class="container">
          <div class="boxes">
            <div class="one-s box">
              <img src={cleanworld2} />
              <h3>{t("website_name")}</h3>
              <p>{t("cleanworld_admin")}</p>
              <div class="img-app">
                <NavLink
                  to="https://apps.apple.com/us/app/cleanworld-admin/id6450494501"
                  target="_blank"
                >
                  {" "}
                  <img src={appstore} class="img-btn" />
                </NavLink>
                <NavLink
                  to="https://play.google.com/store/apps/details?id=com.accruon.admin.cleanworld"
                  target="_blank"
                >
                  {" "}
                  <img src={googleplay} class="img-btn" />
                </NavLink>
              </div>
            </div>
            <ul class="box-list">
              <li>Admin App</li>
              <li> Manager App</li>
              <li>Supervisor App</li>
              <li>Driver App & Worker App </li>
            </ul>

           
          </div>
        </div>
      </section> */}
    </>
  );
};
export default HeaderSectionPage;
